/* EzAir - Auto Add Air */

/* ADA fix for Firefox */
input:-moz-focusring,
button:-moz-focusring {
	outline: 1px solid #3e7eb7;
}
/* END ADA fix for Firefox */

/* General Rules  */
.box-shadow-small {
	box-shadow: 0px 0px 6px rgba(0,0,0,.3);
}
.center-block {
	float: none !important;
	margin-left: auto;
	margin-right: auto;
}
.border-radius-3 {
	border-radius: 3px;
}
.border-top-radius-3 {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.border-bottom-radius-3 {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.web-font .ltr-spacing-0 {
	letter-spacing: normal;
}
.of-hidden {
	overflow: hidden;
}

/* Sale Banner */

.sale-rbn-right-15 {
	margin-right: -23px; /* -15px to the right for container's padding, -8px for wrap-under effect */
}
.sale-rbn-banner {
	background-color: #bd0000;
	color: #fff;
	position: relative;
	padding: 3px 10px;
	box-shadow: 1px 2px 3px rgba(0,0,0,.6);
	margin-left: -20px;
}
.sale-rbn-banner:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	right: 0px;
	bottom: 100%;
	border-width: 4px;
	border-style: solid;
	border-color: transparent transparent #510404 #510404;
}
.sale-rbn-detail {
	padding: 5px 30px 5px 10px;
}

.itn-view-saved .sale-rbn {
	display: none;
} 

/* Auto Add Air */

.complex-tooltip-content {
	display: none;
}

.show-tooltip .price-breakdown {
	background: none;
	border: none;
	outline: none;
	margin: 0;
	padding: 0;
}

.show-tooltip .price-breakdown:focus {
	outline: 1px dotted initial;
}

#rec-flight {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
	max-width: 760px;
	background: #f8f8f8;
}
.itn-carrier-logo {
	max-height: 20px;
	margin-top: 2px;
}
#ez-manage-summary .itn-carrier-logo {
	max-height: 50px;
}
.itn-row {
	position: relative;
}
.itn-stop-container {
	position: relative;
	width: 100%;
	z-index: 5;
}
.itn-line-img-container {
	z-index: 1;
	position: absolute;
	width: 100%;
	top: 25px;
}

/*.ezair .itn-layover {
	list-style: none;
	display: inline-block;
	padding: 0 3%;
	line-height: 14px;
}*/

.itn-final {
	float: right;
	text-align: left;
}
.itn-stop-time {
	letter-spacing: normal;
}
.itn-stop-total-3 .itn-layover {
	padding: 0 2%;
}
.itn-stop-total-4 .itn-layover {
	padding: 0 1%;
}
.itn-stop-total-3 .itn-stop-time, .itn-stop-total-4 .itn-stop-time  {
	text-indent: -9999px;
}

/*.itn-row:nth-of-type(odd) {
	background: #fff;
}*/
.itn-row {
	background-color: white;
}
.itn-row:nth-of-type(even), .lt-ie9 .itn-row ~ .itn-row {
	background-color: #f7f7f7;
}
.borderradius .itn-row:nth-of-type(odd) .itn-stop-img, .no-borderradius .itn-row:nth-of-type(odd) .itn-stop-img {
	background-color: #fff;
}

.no-boxshadow .itn-route, .no-boxshadow .ez-fare-card,.no-boxshadow .ez-multicity-search .ez-card-cell,.no-boxshadow .itn-bothways .ez-card-cell {
	border:  1px solid #A8C1D7;
}
.no-boxshadow .ez-multicity-search .ez-fare-card, .no-boxshadow .ez-multicity-search #ez-autoair .ez-fare-card, .no-boxshadow .itn-bothways .ez-fare-card {
	border: 0;
}
.no-boxshadow #ez-autoair .itn-route, .no-boxshadow #ez-autoair .itn-oneway .ez-fare-card {
	border:  1px solid #d0d1d2;
}


/*.no-boxshadow .itn-row {
	background: #fff;
}*/
.lt-ie9 .itn-row ~ .itn-row {
	/*background-color: #f7f7f7;*/
	border-bottom: 10px solid #fff;
	border-top: 1px solid #d0d1d2;
}
.lt-ie9 #ez-dpt-summary .itn-row ~ .itn-row {
	border-top: 0;
}
.lt-ie9 .itn-row ~ .itn-row .itn-stop-img {
}
.itn-row:last-child:nth-of-type(even) {
	border-bottom: 10px solid #fff;
}
.itn-line {
	position: absolute;
	top: 10px;
	padding: 0;
}
.itn-circle {
	width: 9px;
	height: 9px;
	border: 5px solid black;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	top: 0;
}
.no-borderradius .itn-circle {
	background-image: url(/images/airbook/sprite_air-icons.png);
	background-position: -10px -120px;
	width: 13px;
	height: 13px;
	border: 0;
	border-radius: 0;
	top: -1px;

}
.itn-circle-left {
	left: 0;
}
.itn-circle-right {
	right: 0;
}
.no-borderradius .itn-circle-right {
	right: -1px;
}
.itn-line-img {
	position: absolute;
	top: 4px;
	left: 0;
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
    background: black;
    border: 1px solid black;
}
.itn-stop-img {
	background: #f7f7f7;
}

.itn-layover-nochange .itn-stop-img {
	border-radius: 50%;
}
.itn-layover .itn-stop-img {
	background-image: url(/images/airbook/sprite_air-icons.svg);
	background-repeat: no-repeat;
	background-position: -3px 3px;
	height: 32px;
	width: 25px;
	margin: auto;
	position: relative;
}
.no-svg .itn-layover .itn-stop-img {
	background-color: #fff;
	background-image: url(/images/airbook/sprite_air-icons.png);
	/*background-position: -3px -3px;*/
}
.borderradius .itn-layover-nochange .itn-stop-img {
	background: #f7f7f7;
    border: 2px solid black;
    width: 11px;
    height: 11px;
    margin: 10px auto;
    display: table; /* hack to fix strange width changes in Chrome when resizing window, seems related to the parent container being inline-block */
  }
.no-borderradius .itn-layover-nochange .itn-stop-img {
	background-image: url(/images/airbook/sprite_air-icons.png);
	width: 12px;
	height: 13px;
	background-position: -42px -120px;
	margin: 9px auto;
}
.itn-total-duration {
	position: absolute;
	width: 100%;
	bottom: 10px;
	left: 0;
}
.web-font .alt-font .itn-total-duration {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 12px;
}
.itn-oper-details,.itn-cabin-class-msg {
	padding: 5px 25px 0;
}

/* Show Details */

/*#ez-itn-details {
	border: 1px solid #bdbdbd;
}*/
/*#ez-itn-details .itn-origin, #ez-itn-details .itn-final {
	padding-top: 0;
}*/
.ez-itn-details .itn-origin {
	padding-right: 10px;
}
.ez-itn-details .itn-line-img-container {
	top: 14px;
	width: 90%;
	left: 5%;
}
.ez-itn-details .itn-total-duration {
	top: 75px;
}
.itn-details + .itn-details {
	margin-top: 40px;
}
#ez-previous-summary-fare {
	margin-top: 40px;
}
.arrow-toggle {
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	margin-left: 5px;
	border-top: 12px solid;
	position: relative;
	top: 2px;
}
.arrow-toggle-up {
	position: static;
	border-top: none;
	border-bottom: 12px solid;
}

.itn-dtl-header {
	position: relative;
	padding-left: 50px;
	background-color: #007ba3;
}
.itn-dtl-header-bg {
	width: 31px;
	height: 23px;
	position: absolute;
	left: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
}
.itn-dtl-leaving .itn-dtl-header-bg {
	background-image: url(/images/airbook/sprite_air-icons.svg); 
	background-repeat: no-repeat;
	background-position: 0px -77px;
}
.no-svg .itn-dtl-leaving .itn-dtl-header-bg {
	background-image: url(/images/airbook/sprite_air-icons.png);
}
.itn-dtl-returning .itn-dtl-header-bg {
	background-image: url(/images/airbook/sprite_air-icons.svg); 
	background-repeat: no-repeat;
	background-position: -33px -77px;
}
.no-svg .itn-dtl-returning .itn-dtl-header-bg {
	background-image: url(/images/airbook/sprite_air-icons.png);
}
.itn-dtl-title {
	margin: 0;
	display: inline-block;
	padding-top: 2px;
}
.itn-details .itn-carrier-logo {
	display: block;
	height: 21px;
	/*float: left;*/
	margin: auto;
}
/*.itn-dtl-cell {
	display: table-cell;
	float: none;
	vertical-align: top;
}*/

/* Fare Tables */

.lt-ie9 .no-rwd .itn-fares {
	padding-left: 15px;
	padding-top: 0;
}

.fare-table-wrapper {
	border-radius: 4px;
	padding-bottom: 3px;	
}
.ft-table {
	width: 100%;
	font-size: 13px;
}
.alt-font .ft-table {
	font-size: 14px;
}
.ft-title {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	margin: 0;
}
/*.ft-restricted .ft-title {
	background-color: #f05a28;
}
.ft-flexible .ft-title {
	background-color: #0D2F56;
}*/
.ft-table th {
	border-bottom: 1px solid #b2b2b2;
	vertical-align: bottom;
	font-weight: 400; /* override style in old ezair.style.css - can be removed after redesign */
}
.ft-table td:first-child, .ft-table th:first-child {
	padding-left: 10px;
}
.ft-table td:last-child, .ft-table th:last-child {
	padding-right: 10px;
}
.ft-table th, .ft-table td {
	text-align: center;
	padding: 5px 3px;
}
.ft-table tr:first-child td {
	padding-top: 10px;
}
.ft-table .ft-total-label {
	text-align: left;
}
.ft-table tbody tr:last-child td {
	padding-bottom: 10px;
}
.fare-table-small th, .fare-table-small td {
	font-size: 86.66666666666667%;
	letter-spacing: normal;
	padding-left: 2px !important;
	padding-right: 2px !important;
}
.fare-table-small .ft-total-label {
	padding-left: 10px !important;
}

/* Lightbox */

.mfp-content #popupTitleArea .title,
.popupContainer .article-header h1,
.mfp-container .article-header h1 {
    min-height: 36px;
    background-position: 0 -40px;
    padding-top: 8px;
    font-size: 2.461538461538462em;
    text-align: center;
    display: block;
}
.mfp-content #popupContainer .itn-container-wrapper {
	padding: 30px;
}
/*.ezair-results .mfp-content #popupContent, .ezair-search .mfp-content #popupContent {
	padding: 30px;
}*/

/* Removing styles for the lightbox view of itineraries */

#popupContainer .itn-row:last-child:nth-of-type(even) {
	border-bottom: none;
}
#popupContainer .popupContainer {
	border-bottom: none;
}
.ez-fare-chart .mfp-close-wrapper,
.ez-worry-free-popup .mfp-close-wrapper {
	width: 560px;
	margin: 0 auto;
}
.ez-class-restictions .mfp-close-wrapper {
	max-width: 665px;
	margin: 0 auto;
}
.mfp-wrap ~ .ui-tooltip,
.mfp-wrap ~ .ui-tooltip-princess {
	z-index: 1000 !important;
}
.mfp-wrap ~ .ui-tooltip,
.mfp-wrap ~ .ui-tooltip-princess {
	z-index: 100000 !important;
}
.ez-summary-fare-details .itn-baggage-link {
	font-family: var(--red-hat-text);
	text-decoration: underline;
}
.alt-font .ez-summary-fare-details .itn-baggage-link {
	font-family: "Roboto", Arial, Helvetica, sans-serif;
}
.ez-summary-fare-details .itn-baggage-link:hover {
	text-decoration: none;
}
#ez-flight-summary .itn-details {
	position: relative;
}

/* Schedule Change Notification Message */

.schedule-change-warning {
	height: auto;
	width: 100%;
	display: inline-flex;
	justify-content: center;
}
.schedule-change-warning-icon {
	width: 100%;
    height: 100%;
    display: block;
	background-repeat: no-repeat;
	background-image: url(/images/global/sprite_pc-icons.svg);
}
.segment-schedule-change-message .schedule-change-warning-icon {
    background-size: 475px;
	background-position: 0px -128px;
}
.schedule-change-warning  .schedule-change-warning-icon {
    background-size: 595px;
	background-position: 0px -162px;
}
.segment-schedule-change-message {
	display: flex;
	justify-content: center;
}
.segment-schedule-change-message div {
	width: 16px;
    height: auto;
    display: block;
    margin-top: 2px;
	margin-right: 5px;
}
.schedule-change-warning div {
	width: 20px;
    height: 22px;
    display: block;
    margin-top: 12px;
    margin-right: 5px;
}
.segment-schedule-change-message p {
	margin: 0;
    padding-top: 3px;
}
.segment-info-wrapper {
	width: 98%;
	margin: 0 auto;
}
.segment-schedule-info-changed {
	display: flex;
	padding: 4px;
	border: 2px solid #bd0000;
}
.segment-schedule-info-container {
	display: flex;
	padding: 4px;
}

@media print {
	
	* {
		box-shadow: none !important; /* box-shadow results in a black background after normalize turns all bgs transparent */
	}
	/* keep the plane icon images */
	.itn-layover .itn-stop-img {
		background-image: url(/images/airbook/sprite_air-icons.svg) !important;
		background-repeat: no-repeat !important;
	}
	.itn-stop-img {
		background-color: #fff !important; /* background for airplane icon used in flight stops */
	}
	.sale-rbn-banner {
		color: #bd0000;
	}
	.fare-table.hidden {
		display: block !important;
		visibility: visible !important;
	}
	.ft-toggle-link, .fare-add-booking, .ft-terms {
		display: none;
	}
	.ft-title {
		border-bottom: 1px solid #b2b2b2;
	}
	.ft-restricted .ft-title {
		color: #f05a28;
	}
	.ft-flexible .ft-title {
		color: #0D2F56;
	}
}

/* Summary Page */

.ezair-results #ez-flight-summary .article-header {
	padding-bottom: 30px;
}
@media screen and (max-width: 991px) {
	.no-boxshadow .itn-route {
		border: 0;
	}
}
@media screen and (max-width: 639px) {
	#ez-summary-details .light-border, .ez-summary-fare-details .light-border {
		border: none;
	}
	#ez-summary-details.border-radius-3, .ez-summary-fare-details .border-top-radius-3, .ez-summary-fare-details .border-bottom-radius-3 {
		border-radius: 0;
	}
	#ez-summary-details.box-shadow-small, .ez-summary-fare-details.box-shadow-small {
		box-shadow: none;
	}
	.ez-summary-fare-container .pricing ul:last-child, .ez-summary-fare-container .total p:last-child {
		border-bottom: 1px solid #b2b2b2;
		margin-bottom: 2em;
		padding-bottom: 2em;
	}
	#ez-fare-summary .pricing-tooltip .tooltip,
	.booked-flight-header-details-meta .price-breakdown {
		display: inline-block;
		width: 100%;
		text-align: left;
	}
	.ez-summary-sale-top {
		right: 0;
	}
	/* Schedule Change Notification Message */
	.segment-schedule-info-container,
	.segment-schedule-info-changed {
		display: block;
	}
	.schedule-change-warning div {
		width: 30px;
		margin-right: 0;
		margin-left: 15px;
	}
}
@media screen and (min-width: 640px) and (max-width: 767px) {
	.ez-summary-fare-container .next-step p:first-child {
		border-top: 1px solid #b2b2b2;
		padding-top: 2em;
	}
}
.pricing-list {
	margin: 0 0 2em;
}
.pricing-list li + li {
	margin-top: 1em;
}
.pricing-list + .ez-summary-sale-container {
	margin-top: -1em;
}
.ez-tnc-lightbox table {
	margin: 0 0 5px;
}
@media screen and (min-width: 560px) {
    .ez-fare-chart #popupContainer,
    .ez-worry-free-popup #popupContainer {
	    width: 560px;
    }
	.ez-class-restictions #popupContainer {
	    max-width: 665px;
    }
}
